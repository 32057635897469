<template>
	<v-flex fill-height>
		<v-layout pl-4 pt-4>
			<v-flex v-t="'sa.support.actions.send-document-to-third-app.title'" headline shrink />
		</v-layout>
		<v-layout row wrap pa-4>
			<v-flex xs6 pr-2>
				<w-text-input
v-model="appId" auto-focus
					:label="$t('sa.support.actions.send-document-to-third-app.app_id')" maxlength="191" type="number"
					required></w-text-input>
			</v-flex>
			<v-flex xs6 pl-2>
				<w-text-input
v-model="documentVersionId" auto-focus
					:label="$t('sa.support.actions.send-document-to-third-app.document_version_id')" maxlength="191"
					type="number" required></w-text-input>
			</v-flex>
			<v-flex my-3 shrink>
				<WButton :disabled="!appId || !documentVersionId" @click="execute">
					{{ $t('actions.launch') }}</WButton>
			</v-flex>
		</v-layout>
	</v-flex>
</template>

<script>
import SuperAdminModuleGuard from '@/mixins/ModulesGuards/SuperAdmin/SuperAdminModuleGuard'
	export default {
		name: 'SendDocumentToThirdApp',
		components: {},
		mixins: [SuperAdminModuleGuard],
		props: {},
		data: function () {
			return {
				appId: null,
				documentVersionId: null
			}
		},
		computed: {},
		watch: {},
		created: function () {},
		mounted: function () {},
		destroyed: function () {},
		methods: {
			  execute: function () {
				return this.service.executeCommand({ 
						command: "automation:send-document", 
						params: {
							'app-id' : this.appId,
							'--document-version-id': this.documentVersionId,  
						}
					})
				.then(() => {
					this.appEventBus.emit(this.appEvents.SNACKBAR_SUCCESS, this.$t('sa.support.success'))
				})
        	}
		}
	}
</script>
